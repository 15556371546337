import { MarkGithubIcon } from "@primer/octicons-react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import pwalogo from "../../assets/images/pwalogo.svg";

const Header = () => {
  const navigate = useNavigate();
  const [desktop, setDesltop] = useState(false);

  useEffect(() => {
    const checkWidth = () => {
      if (window.innerWidth > 767) setDesltop(true);
      else setDesltop(false);
    };
    checkWidth();
  }, []);

  return (
    <header>
      <div className="info">
        <div style={{ marginLeft: 10, display: "flex", alignItems: "center" }}>
          {" "}
          <span>Support us with a star or as a sponsor on</span>
          <a
            style={{ marginLeft: 4 }}
            href="https://github.com/pwafire/pwafire"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            @github
          </a>
        </div>
      </div>
      <nav>
        <div className="nav-left">
          <span
            className="nav-title"
            onClick={(event) => {
              event.preventDefault();
              navigate("/");
            }}
          >
            <img className="pwa-logo" src={pwalogo} alt="PWAs" />
            <span role="img" aria-label="Hello">
              v5.1.4-rc.1
            </span>
          </span>
        </div>
        <div className="nav-right">
          <span className="nav-right-inner">
            <span className="nav-item nav-link" style={{ display: "none" }}>
              <NavLink
                to="/get-started"
                className={({ isActive }) => (isActive ? "nav-active" : "")}
              >
                Docs
              </NavLink>
            </span>
            <a
              className="nav-item nav-link"
              href="https://github.com/pwafire/pwafire"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MarkGithubIcon size={desktop ? 32 : 24} />
            </a>
          </span>
        </div>
      </nav>
    </header>
  );
};

export default Header;
