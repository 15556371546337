import { DotIcon } from "@primer/octicons-react";
import Prism from "prismjs";
import { useEffect } from "react";
import { Header, Share, Sidebar } from "../../components";

const CompressionStreams = () => {
  useEffect(() => {
    Prism.highlightAll();
    document.title = "PWA Fire - Compression Streams API";
  }, []);
  return (
    <article>
      <Header />
      <section className="docs-flex">
        <Sidebar />
        <section className="docs-flex-right fade-in docs post">
          <div className="date">
            <span>Last updated -: Nov 22 2024</span>
            <Share
              data={{
                url: "https://docs.pwafire.org/link/compression-streams",
                title: "Compression Streams API",
                text: "Compress and decompress streams of data using the Compression Streams API",
              }}
            />
          </div>
          <h3>Compression Streams API</h3>
          <p className="quote">
            Compress and decompress streams of data using the Compression
            Streams API.
          </p>
          <p>
            <DotIcon />
            Use the <b>compressStream</b> and <b>decompressStream</b> methods to
            handle data compression.
          </p>
          <pre id="compression-code">
            <code className="language-javascript">{`  // 1. Compress data
 const readableStream = await fetch('lorem.txt').then(
    (response) => response.body
  );
  const compressedReadableStream = await pwa.compressStream(readableStream);
  const compressedStream = compressedReadableStream.stream;

  // 2. Decompress data
   const readableCompressedStream = await fetch('lorem.gzip').then(
    (response) => response.body
  );
  const decompressedReadableStream = await pwa.decompressStream(readableCompressedStream);
  const decompressedStream = decompressedReadableStream.stream;
  });`}</code>
          </pre>
        </section>
      </section>
    </article>
  );
};

export default CompressionStreams;
